export default {
  ValueError: function(context) {
    let errorText = context.exception.args[0];
    if (errorText.indexOf('invalid literal for int() with base 10: ') !== -1) {
      let string = errorText.replace('invalid literal for int() with base 10: ', '');
      if (string.length > 2) {
        return `Строку ${string} невозможно преобразовать в целое число, а вы пытаетесь сделать это на строке ${context.lineNumber}.`
      } else {
        return `Пустую строку невозможно преобразовать в целое число, а вы пытаетесь сделать это на строке ${context.lineNumber}.`
      }
    }
  },

  IndexError: function(context) {
    let errorText = context.exception.args[0];
    if (errorText.indexOf('list') !== -1) {
      return `На строке ${context.lineNumber} вы пытаетесь обратиться к несуществующему элементу списка`
    } else if (errorText.indexOf('string') !== -1) {
      return `На строке ${context.lineNumber} вы пытаетесь обратиться к несуществующему элементу строки`
    }
  },

  NameError: function(context) {
    let args = String(context.exception.args).replace('name \'', '').replace('\' is not defined', '');
    return `Вы пытаетесь использовать необъявленную переменную или функцию "${args}" на строке ${context.lineNumber}`;
  },

  SyntaxError: function(context) {
    let errorText = context.exception.args[0];
    if (errorText.indexOf("Unbalanced bracket") !== -1) {
      return `Проверьте незакрытые скобки. Для любой открывающей скобки должна быть закрывающая. 
      Особенно обратите внимание на строку ${context.lineNumber}.`;
    }

    if (errorText.indexOf("EOL while scanning string literal") !== -1) {
      return `Где-то на строке ${context.lineNumber} есть незакрытая кавычка`
    }

    if (errorText.indexOf("unexpected EOF while parsing") !== -1) {
      return `Где-то на строке ${context.lineNumber} есть незакрытая скобка`
    }

    if ((context.exceptionText.indexOf('„') !== -1) || (context.exceptionText.indexOf('”') !== -1)) {
      return `Кавычки-лапки использовать нельзя. На строке ${context.lineNumber} нужны "обычные" кавычки.`;
    }

    if ((errorText.indexOf('«') !== -1) || (errorText.indexOf('»') !== -1)) {
      return `Кавычки-елочки использовать нельзя. На строке ${context.lineNumber} нужны "обычные" кавычки.`;
    }

    let rows = window.__letpy.source.split('\n');
    let i = 0;
    while (rows.length > i) {
      let v = rows[i];
      v = v.split('#')[0];
      if ((v.trim().startsWith('if') && !v.trim().endsWith(':'))) {
        return `Скорее всего вы забыли про двоеточие после условия в if на строке ${context.lineNumber}`;
      } else if ((v.trim().startsWith('else') && !v.trim().endsWith(':'))) {
        return `Похоже, что вы забыли про двоеточие после в else на строке ${context.lineNumber}`;
      } else if ((v.trim().startsWith('elif') && !v.trim().endsWith(':'))) {
        return `Скорее всего вы забыли про двоеточие после условия в elif на строке ${context.lineNumber}`;
      } else if ((v.trim().startsWith('while') && !v.trim().endsWith(':'))) {
        return `Похоже, что вы забыли про двоеточие после цикла while на строке ${context.lineNumber}`;
      } else if ((v.trim().startsWith('for') && !v.trim().endsWith(':'))) {
        return `Скорее всего вы забыли про двоеточие после цикла for на строке ${context.lineNumber}`;
      }
      i++;
    }
  },

  ZeroDivisionError: function(context) {
    return `Где-то в строке ${context.lineNumber} вы поделили на ноль, а на ноль делить нельзя.`
  },

  IndentationError: function(context) {
    let errorText = context.exception.args[0];
    if (errorText.indexOf('expected an indented block') !== -1) {
      return `На строке ${context.lineNumber} нужен отступ. Помните, что отступ в Python — это 4 пробела`
    }

    if (errorText.indexOf('unexpected indent') !== -1) {
      let src = window.__letpy.source;
      if ((src.indexOf('if') !== -1) || (src.indexOf('elif') !== -1) || (src.indexOf('else') !== -1) || (src.indexOf('for') !== -1) || (src.indexOf('while') !== -1)) {
        return `Отступ на строке ${context.lineNumber} неправильный или не нужен совсем. Помните, что отступ в Python — это 4 пробела.`
      }
      return `Отступ на строке ${context.lineNumber} скорее всего не нужен совсем. Помните, что без надобности отступать от левого края в Python нельзя.`
    }
  },

  TypeError: function(context) {
    let errorText = context.exception.args[0];
    if (errorText.indexOf("unsupported operand type(s) for") !== -1) {
      let types = errorText.replace('unsupported operand type(s) for ', '').split(':');
      let operator = types[0];
      types = types[1].split("'").join("").replace(/ /g, '');
      types = types.split('and');
      let lineInfo = `Вы ошиблись в программе на строке ${context.lineNumber}`;

      if (types[0] === types[1]) {
        let typeString = types[0]
            .replace('int', ' с числами').replace('str', ' со строками')
            .replace('float', ' с числами').replace('list', ' со списками')
        return `${lineInfo}. Нельзя использовать оператор "${operator}" ${typeString}.`
      } else {
        let typeStringFirst = types[0]
            .replace('int', 'с числом').replace('str', 'со строкой')
            .replace('float', 'с числом').replace('list', 'со списком')
        let typesStringSecond = types[1]
            .replace('int', 'и числом').replace('str', 'и строкой')
            .replace('float', 'и числом').replace('list', 'и списком')
        return `${lineInfo}. Нельзя использовать оператор "${operator}" ${typeStringFirst} ${typesStringSecond}. Это разные типы данных!`
      }
    } else if (errorText.indexOf("unorderable types:") !== -1) {
      let types = errorText.replace('unorderable types:', '').replace(/\(\)/g, '').replace(/'/g, '');
      types = types.replace('>=', 'и').replace('>', 'и').replace('<', 'и');
      types = types
          .replace(/str/g, 'строку')
          .replace(/int/g, 'число')
          .replace(/float/g, 'число')
          .replace(/list/g, 'список')
          .replace(/tuple/g, 'кортеж');
      return `Вы ошиблись на строке ${context.lineNumber}. Нельзя сравнивать ${types}.`;

    } else if (errorText.indexOf("not supported between instances of") !== -1) {
      let types = errorText.replace('not supported between instances of', '').replace(/\(\)/g, '').replace(/'/g, '');
      types = types.replace('>=', '').replace('>', '').replace('<', '');
      types = types.replace('and', 'и')
      types = types
          .replace(/str/g, 'строку')
          .replace(/int/g, 'число')
          .replace(/float/g, 'число')
          .replace(/list/g, 'список')
          .replace(/tuple/g, 'кортеж')
      return `Вы ошиблись на строке ${context.lineNumber}. Нельзя сравнивать ${types}.`;

    } else if (/Can't convert(.*) to str implicitly$/.test(errorText)) {
      let type = errorText.replace("Can't convert ", "").replace(" to str implicitly", "");
      if ((type === "int") || (type === "float")) {
        return `На ${context.lineNumber} строке программы вы пытаетесь склеить число со строкой. 
        Число надо явно преобразовать в строку с помощью функции str.`
      }
    } else if ((/missing \d+ positional argument/.test(errorText)) || (errorText.indexOf('takes exactly 1 argument (0 given)') !== -1)) {
      let func = errorText.split(' ')[0];
      return `Похоже, в функции ${func} на строке ${context.lineNumber}, вы указали не все обязательные параметры. 
      Найдите описание этой функции в нашем справочнике или, например, в google.`

    } else if (errorText.indexOf('object does not support item assignment') !== -1) {
      let type = errorText.replace(' object does not support item assignment', '');
      return `Тип данных ${type} неизменяемый и его элементы нельзя переопределять. 
      А вы пытаетесь сделать это на строке ${context.lineNumber}`;

    } else if (errorText.indexOf('can\'t compare') !== -1) {
      let type = errorText.replace('can\'t compare', '').replace(' to ', ' и ');
      return `Нельзя сравнивать ${type} на строке ${context.lineNumber}`;
    }
  }
}