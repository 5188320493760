"use strict";

import Vue from 'vue';
import Interpreter from "./Interpreter";


Interpreter.install = function (Vue) {
  Interpreter.init();
  Interpreter.reset();

  Vue.prototype.$_interpreter = Vue.observable(Interpreter.public);


  Vue.mixin({
    methods: {
      $focusOnInputElement() {
        if (this.$interpreter.inputInProgress || this.$_interpreter.userInputInProgress) {
          this.$inputElement.focus();
        }
      },
    },

    computed: {
      $inputElement: {
        get() {
          return Interpreter.inputElement;
        }
      },

      $interpreter: {
        get() {
          let result = {
            showCanvasWindow: window.__letpy_reactive.showCanvasWindow,
            currentDebugStep: window.__BRYTHON__.executionBindings.current_step_number,
            mode: window.__BRYTHON__.executionBindings.mode,
            isVerification: window.__letpy_reactive.isVerification && window.__BRYTHON__.executionBindings.is_running,
            inputInProgress: window.__letpy_reactive.inputInProgress,
            reloadUserFilesFlag: window.__BRYTHON__.executionBindings.reload_user_files_flag
          }

          if (this.$_interpreter.version > 1) {
            result.isRunning = this.$_interpreter.isRunning
          } else {
            result.isRunning = window.__BRYTHON__.executionBindings.is_running
          }
          return result
        },
      },

      $interpreterErrorHint: {
        get() {
          return Interpreter.getErrorHint(window.__letpy_reactive.stdout);
        }
      },

      $stdout: {
        get() {
          let stdout = window.__letpy_reactive.stdout.replace(/</g, '&lt;');
          if (this.$_interpreter.version > 1) {
            stdout = this.$_interpreter.stdout.replace(/</g, '&lt;');
          }

          stdout = stdout.replace(/>/g, '&gt;');

          if (this.reactive__letpy.inputInProgress || Interpreter.public.userInputInProgress) {
            stdout += (Interpreter.public.userInputInProgress ? Interpreter.public.userInputValue : this.reactive__letpy.inputValue)
            if (this.reactive__letpy.inputInFocus || Interpreter.public.userInputInFocus) {
              stdout += '<span class="blinked-cursor">_</span>';
            }
          }

          let i = 0;
          let stdoutAnsi = '<span>';
          let ansiEscapeStarted = false;
          let colorEscapeStarted = false;
          let colorCode = '';
          let currentMode = 'normal';
          let currentFore = 0;
          let currentBack = 0;

          while (i < stdout.length) {
            if (stdout[i] === "\u001b") {
              ansiEscapeStarted = true;
              i++;
              continue;
            }

            if ((stdout[i] === '[') && (ansiEscapeStarted)) {
              colorEscapeStarted = true;
              i++;
              continue
            } else if (ansiEscapeStarted) {
              ansiEscapeStarted = false;
            }

            if (colorEscapeStarted) {
              if (stdout[i] !== 'm') {
                colorCode += stdout[i];
              } else {
                colorCode = parseInt(colorCode);
                if (colorCode === 1) {  // BRIGHT
                  currentMode = 'bright'
                } else if (colorCode === 2) {  // DIM
                  currentMode = 'dim'
                } else if (colorCode === 22) { // NORMAL
                  currentMode = 'normal'

                } else if (colorCode === 0) {
                  currentMode = 'normal'
                  currentFore = 0;
                  currentBack = 0;
                } else if (((colorCode >= 30) && (colorCode <= 39)) || ((colorCode >= 90) && (colorCode <= 97))) {
                  currentFore = colorCode;
                } else if (((colorCode >= 40) && (colorCode <= 49)) || ((colorCode >= 100) && (colorCode <= 107))) {
                  currentBack = colorCode;
                }
                stdoutAnsi += `</span><span class="ansi-${currentMode}-${currentBack} ansi-${currentMode}-${currentFore} ">`
                colorEscapeStarted = false;
                ansiEscapeStarted = false;
                colorCode = 0;
              }
              i++;
              continue;
            }
            stdoutAnsi += stdout[i]
            i++;
          }
          stdoutAnsi += '</span>'
          return stdoutAnsi;
        }
      }
    },

    data() {
      return {
        reactive__executionBindings: window.__BRYTHON__.executionBindings,
        reactive__letpy: window.__letpy_reactive
      }
    }
  })
}

Vue.use(Interpreter);
export default Interpreter;
