import ApplicationsService from '@/services/ApplicationsService.js'


export const namespaced = true

export const state = {
  app: {}
}

export const mutations = {
  SET_APP(state, appObject) {
    state.app = appObject
  }
}

export const actions = {
  fetchAppBySlug({ commit }, slug) {
    return ApplicationsService.get(slug).then((response) => {
      commit('SET_APP', response.data)
    })
  }
}

export const getters = {

}