import Vue from 'vue'


export default class APIService {
  constructor(baseUrl) {
    if (baseUrl === undefined) {
      throw '"baseUrl" param should be set.'
    }

    this.baseUrl = baseUrl
  }

  constructURL(urlPart) {
    return `${this.baseUrl}${((urlPart === undefined) ? '':`${urlPart}/`)}`
  }

  get(urlPart = undefined, params = {}) {
    let url = this.constructURL(urlPart)
    return Vue.axios.get(url, {'params': params})
  }

  post(data, urlPart) {
    let url = this.constructURL(urlPart)
    return Vue.axios.post(url, data)
  }

  delete(urlPart, payload) {
    let url = this.constructURL(urlPart)
    if (payload !== undefined) {
      return  Vue.axios.delete(url, {data: payload})
    }
    return Vue.axios.delete(url)
  }

  patch(data, urlPart) {
    let url = this.constructURL(urlPart)
    return Vue.axios.patch(url, data)
  }

  put(data, urlPart) {
    let url = this.constructURL(urlPart)
    return Vue.axios.put(url, data)
  }
}

