import InterpreterHints from "./InterpreterHints";
import brythonRunnerWorkerSrc from "!!raw-loader!../interpreter/Interpreter.worker";
import brythonRunnerVerificationWorkerSrc from "!!raw-loader!../interpreter/Interpreter.verification.worker";

const pixelmatch = require('pixelmatch');

//import brythonModule from "!!raw-loader!brython";
//import brythonStdlibModule from "!!raw-loader!brython/brython_stdlib";
import sleepPythonSrc from '!!raw-loader!../interpreter/scripts/sleep.py'
import inputPythonSrc from '!!raw-loader!../interpreter/scripts/input.py'
import openPythonSrc from '!!raw-loader!../interpreter/scripts/open.py'

import verificationSrc from '!!raw-loader!../interpreter/scripts/verification.py'
import verificationTemplateSrc from '!!raw-loader!../interpreter/scripts/verification_template.py'

import tokenizeModuleSrc from '!!raw-loader!../interpreter/scripts/tokenize.py'
import coloramaModuleSrc from '!!raw-loader!../interpreter/scripts/colorama.py'
import tkinterSrc from '!!raw-loader!../interpreter/scripts/tkinter/__init__.py'
import tkinterSrcConstants from '!!raw-loader!../interpreter/scripts/tkinter/constants.py'
import tkinterSrcShapes from '!!raw-loader!../interpreter/scripts/tkinter/_shapes.py'

import {TkinterInit, TkinterHandle} from "@/plugins/interpreter/TkinterClassWrappers";

export default {
  inputElement: null,

  public: {
    stdout: '',
    isRunning: false,
    userInputInProgress: false,
    userInputValue: '',
    userInputInFocus: false,
    version: 1,
    loading: false
  },

  // eventArray: null,
  // dataArray: null,
  worker: null,
  brythonModule: null,
  brythonStdlibModule: null,

  createWorker(sourceCode) {
    window.URL = window.URL || window.webkitURL
    let blob;
    try {
      blob = new Blob([sourceCode], { type: 'application/javascript' })
    } catch (e) {
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder
      // eslint-disable-next-line no-undef
      blob = new BlobBuilder()
      blob.append(sourceCode)
      blob = blob.getBlob()
    }
    return new Worker(URL.createObjectURL(blob))
  },

/*
  sendToWorker_(data) {
    let encoder = new TextEncoder();
    let result = encoder.encode(JSON.stringify(data));
    for (let i = 0; i < result.length; i++) {
      this.dataArray[i] = result[i];
    }
    // eslint-disable-next-line no-undef
    Atomics.store(this.eventArray, 0, 123);
    // eslint-disable-next-line no-undef
    Atomics.store(this.eventArray, 1, result.length);
    // eslint-disable-next-line no-undef
    Atomics.notify(this.eventArray, 0, 1);
  },*/

  sendToWorker(data, dataArray, eventArray) {
    let encoder = new TextEncoder();
    let result = encoder.encode(JSON.stringify(data));
    for (let i = 0; i < result.length; i++) {
      dataArray[i] = result[i];
    }
    // eslint-disable-next-line no-undef
    Atomics.store(eventArray, 0, 123);
    // eslint-disable-next-line no-undef
    Atomics.store(eventArray, 1, result.length);
    // eslint-disable-next-line no-undef
    Atomics.notify(eventArray, 0, 1);
  },

  _runFile(file) {
    if (!this.brythonModule || !this.brythonStdlibModule) {
      throw "Brython module didn't load"
    }
    // eslint-disable-next-line no-undef
    const eventBuffer = new SharedArrayBuffer(8);
    // eslint-disable-next-line no-undef
    const dataBuffer = new SharedArrayBuffer(1024);
    // this.eventArray = new Int32Array(eventBuffer);
    // this.dataArray = new Int8Array(dataBuffer);

    const eventArray = new Int32Array(eventBuffer);
    const dataArray = new Int8Array(dataBuffer);

    this.worker = this.createWorker(brythonRunnerWorkerSrc);

    this.worker.postMessage({
      eventBuffer: eventBuffer,
      dataBuffer: dataBuffer,
      initModules: [this.brythonModule, this.brythonStdlibModule],
      // fileId: file.id,
      fileContent: file.content,
      fileName: file.title,
      pythonPath: [`${process.env.VUE_APP_MAIN_HOST}/api/v2/user-files/${btoa(file.path)}/import/`],
      initScripts: [
          sleepPythonSrc,
          inputPythonSrc,
          openPythonSrc
      ],
      modules: {
        'colorama': ['.py', coloramaModuleSrc, []],
        'tkinter': ['.py', tkinterSrc, [], 1],
        'tkinter.constants': ['.py', tkinterSrcConstants, []],
        'tkinter._shapes': ['.py', tkinterSrcShapes, []],
        'verification': ['.py', verificationSrc, [], 1]
      }
    });

    TkinterInit(this);

    this.worker.onmessage = (event) => {

      if (event.data.type === 'script.started') {
        this.public.stdout = '';
        this.public.isRunning = true;
      } else if (event.data.type === 'stdout.write') {
        this.public.stdout += event.data.value;
      } else if (event.data.type === 'stderr.write') {
        this.public.stdout += event.data.value;
      } else if (event.data.type === 'script.finished') {
        this.stopWorkerExecution();
      } else if (event.data.type === 'stdin.read') {
        this.public.userInputInProgress = true;

        this.inputElement.onfocus = () => {
          this.public.userInputInFocus = true;
        }
        this.inputElement.onblur = () => {
          this.public.userInputInFocus = false;
        }

        this.inputElement.focus();
        this.inputElement.value = '';

        this.inputElement.oninput = (e) => {
          this.public.userInputValue = e.target.value;
          let stdout = document.querySelector('.stdout > pre').getBoundingClientRect()
          this.inputElement.style.top = `${stdout.y + stdout.height}px`
        }

        this.inputElement.onkeypress = (e) => {
          if ((e.keyCode === 13) && (this.public.userInputInProgress)){
            this.public.userInputInProgress = false;
            this.public.stdout += (this.public.userInputValue + '\n');
            this.public.userInputInProgress = false;
            this.sendToWorker({value: this.public.userInputValue}, dataArray, eventArray);
            this.public.userInputValue = '';
          }
        }

      } else if (event.data.type === 'tkinter') {
        TkinterHandle(event.data);
      } else {
        console.log(event);
      }
    }
  },

  stopWorkerExecution() {
    this.worker.terminate();
    this.public.isRunning = false;
    if (this.public.userInputInProgress) {
      this.public.stdout += this.public.userInputValue;
    }
    this.public.userInputInProgress = false
    this.public.userInputValue = ''
  },


  init() {
    window.pixelmatch = pixelmatch;
    window.__letpy = {
      'stdout': '',
      'stdoutHelp': '',
      'errorHint': '',
      'is_verification': false,
      'version': 2,
      'host': process.env.VUE_APP_MAIN_HOST,
      'verificationEvents': {},
      'tkinter': {
        handlers: {}
      },
      'locale': 'ru'
    };

    window.__letpy_reactive = {
      'stdout': '',
      'showCanvasWindow': false,
      'isVerification': false,
      'inputInProgress': false,
      'inputValue': '',
      'inputInFocus': false
    };

    this.inputElement = document.createElement('input');
    this.inputElement.setAttribute('style', "opacity: 0; font-size: 1px; height: 1px; width: 1px; position: absolute; top:0;");
    this.inputElement.setAttribute('autocapitalize', 'off');
    this.inputElement.setAttribute('spellcheck', 'false');

    document.body.appendChild(this.inputElement);

    if (window.__BRYTHON__ === undefined) {
      window.__BRYTHON__ = {
        builtins: {},
        executionBindings: {}
      }
    }
    window.__BRYTHON__.builtins['input'] = async (msg) => {

      if (window.__letpy.is_verification) {
        if (window.__letpy.builtins.input__calls === undefined) {
          window.__letpy.builtins.input__calls = 0;
        }
        window.__letpy.random.input__calls += 1;

        if (typeof window.__letpy.builtins.input === "string") {
          return window.__letpy.builtins.input;
        } else if ((typeof window.__letpy.builtins.input === "object") && (window.__BRYTHON__.builtins['hasattr'](window.__letpy.builtins.input, '__next__'))) {
          if (window.__letpy.builtins.input.counter < (window.__letpy.builtins.input.len - 1)) {
            return window.__BRYTHON__.builtins['next'](window.__letpy.builtins.input)
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
      if (msg) {
        msg = window.__BRYTHON__.$call(window.__BRYTHON__.builtins['str'])(msg)
      }
      window.__BRYTHON__.stdout.write(msg || '');

      this.inputElement.onfocus = () => {
        window.__letpy_reactive.inputInFocus = true;
      }
      this.inputElement.onblur = () => {
        window.__letpy_reactive.inputInFocus = false;
      }

      this.inputElement.focus();
      this.inputElement.value = '';
      window.__letpy_reactive.inputInProgress = true;
      window.__letpy_reactive.inputValue = '';

      return new Promise(resolve => {
        this.inputElement.oninput = (e) => {
          window.__letpy_reactive.inputValue = e.target.value;
          let stdout = document.querySelector('.stdout > pre').getBoundingClientRect()
          this.inputElement.style.top = `${stdout.y + stdout.height}px`
        }

        this.inputElement.onkeypress = (e) => {
          if ((e.keyCode === 13) && (window.__letpy_reactive.inputInProgress)){
            window.__letpy_reactive.inputInProgress = false;
            window.__BRYTHON__.stdout.write(window.__letpy_reactive.inputValue + '\n');
            resolve(window.__letpy_reactive.inputValue);
          }
        }

        let interval = setInterval(() => {
          if (window.__BRYTHON__.executionBindings.stop_execution) {
            clearInterval(interval);
            window.__letpy_reactive.inputInProgress = false;
            window.__BRYTHON__.stdout.write(window.__letpy_reactive.inputValue + '\n');
            resolve(window.__letpy_reactive.inputValue);
          }
        }, 100);
      });
    }


    window.__requests_proxy_url = process.env.VUE_APP_REQUESTS_MODULE_PROXY_URL
    window.__letpy_exec_number = 0;

    this.hiddenOutput = document.createElement('div')
    this.hiddenOutput.setAttribute('id', 'hidden_output')
    this.hiddenOutput.setAttribute('style', 'display: none')

    document.body.appendChild(this.hiddenOutput);

    window.hasPythonErrors = function (string_) {
        var errors = ['Traceback (most recent call last)', 'SyntaxError:', 'IndentationError:'];
        var result = false;
        errors.forEach(function (v) {
          if (string_.indexOf(v) !== -1) {
            result = true;
          }
        });
        return result;
      };

    function catch_in_handlers($err, handler) {
      window.__BRYTHON__.executionBindings.stop_execution = true;
      window.__letpy.lastError = $err;
      var _b_ = window.__BRYTHON__.builtins;
      var $B = window.__BRYTHON__;

      if ($err.$py_error === undefined) {
        console.log('Javascript error', $err)
        $err = _b_.RuntimeError($err + '')
      }
      var name = $B.class_name($err),
          trace = _b_.getattr($err, 'infoWithInternal')
      let lines = trace.split('\n');
      if (lines.length === 3) {
        trace = lines[0] + '\n' + lines[2];
      }

      trace += '\n' + name + ': ' + $err.args
      trace += '\n';

      $err.isHandlerError = handler
      try {
        _b_.getattr($B.stderr, 'write')(trace)
      } catch (print_exc_err) {
        console.log(trace)
      }
    }


    window._Canvas__click_event_handler = async function (f, x, y) {
      if (window.__BRYTHON__.executionBindings.is_running) {
        try {
          await f(x, y);
        } catch ($err) {
          catch_in_handlers($err, 'canvas.onclick');
          throw $err
        }
      }
    };

    window._Canvas__right_click_event_handler = async function (f, x, y) {
      if (window.__BRYTHON__.executionBindings.is_running) {
        try {
          await f(x, y);
        } catch ($err) {
          catch_in_handlers($err, 'canvas.onclick');
          throw $err
        }
      }
    };

    window._Canvas__keydown_event_handler = async function (f, code, key) {
      if (window.__BRYTHON__.executionBindings.is_running) {
        try {
          if (code === 'all_keys_handler') {
            await f(key);
          } else {
            await f();
          }
        } catch ($err) {
          catch_in_handlers($err, 'canvas.onkey');
          throw $err
        }
      }
    };

    window._Canvas__keyup_event_handler = async function (f, code, key) {
      if (window.__BRYTHON__.executionBindings.is_running) {
        try {
          if (code === 'all_keys_handler') {
            await f(key);
          } else {
            await f();
          }
        } catch ($err) {
          catch_in_handlers($err, 'canvas.onkey');
          throw $err
        }
      }
    };

    function handle_error($err) {
      // window.__BRYTHON__.executionBindings.stop_execution = true;
      window.__letpy.lastError = $err;
      var _b_ = window.__BRYTHON__.builtins;
      var $B = window.__BRYTHON__;

      if ($err.$py_error === undefined) {
        console.log('Javascript error', $err)
        $err = _b_.RuntimeError($err + '')
      }
      var name = $B.class_name($err),
          trace = _b_.getattr($err, 'infoWithInternal')

      let lines = trace.split('\n');
      if (lines.length === 3) {
        trace = lines[0] + '\n' + lines[2];
      }

      trace += '\n' + name + ': ' + $err.args
      trace += '\n';
      try {
        _b_.getattr($B.stderr, 'write')(trace)
      } catch (print_exc_err) {
        console.log(trace)
      }
    }

    window._Widget__tkinterAddHandler = function(widgetId, eventName, handler) {
      if (!Object.prototype.hasOwnProperty.call(window.__letpy.tkinter.handlers, widgetId)) {
        window.__letpy.tkinter.handlers[widgetId] = {}
      }
      window.__letpy.tkinter.handlers[widgetId][eventName] = handler
    }

    window._Widget__tkinterAddListeners = function(widgetId, el) {
      function getHandler(name) {
        if (!Object.prototype.hasOwnProperty.call(window.__letpy.tkinter.handlers, widgetId)) {
          return false;
        }
        if (!Object.prototype.hasOwnProperty.call(window.__letpy.tkinter.handlers[widgetId], name)) {
          return false;
        }
        return window.__letpy.tkinter.handlers[widgetId][name];
      }

      el.addEventListener('click', async function(e) {
        if (window.__BRYTHON__.executionBindings.is_running) {
          let handler = getHandler('<Button-1>')
          if (handler) {
            try {
              let Event = $B.make_class("Event")
              Event.__str__ = function(self) {
                return `<ButtonPress event num=1 x=${self.x} y=${self.y}>`;
              }
              await $B.$call(handler)({
                'x': e.offsetX,
                'y': e.offsetY,
                '__class__': Event
              });
            } catch ($err) {
              handle_error($err);
              throw $err
            }
          }
        }
      });

    }
  },

  hideCanvasWindow() {
    window.__letpy_reactive.showCanvasWindow = false;
  },

  reset() {
    window.__letpy_reactive.stdout = '';
    window.__letpy.stdout = '';
    window.__letpy.source = '';
    window.__letpy.errorHint = '';
    window.__letpy.__letpy_sleep_called = false;
    window.__letpy.is_verification = false;
    window.__letpy_reactive.isVerification = false;
    window.__letpy.verification = {};
    window.__letpy.lastError = undefined;
    window._datetime__letpy = {};
    window.__letpy.builtins = {};
    window.__letpy.random = {};
    window.__letpy.executionInfo = {};
    window.__letpy.tkinter.handlers = {};

    window.__BRYTHON__.executionBindings.localsHistory = [];
    window.__BRYTHON__.executionBindings.file_id = null;
    window.__BRYTHON__.executionBindings.folder_id = null;
    window.__BRYTHON__.executionBindings.app_id = null;
    window.__BRYTHON__.executionBindings.reload_user_files_flag = false;

    window.__letpy.__canvas = undefined;
    this.hiddenOutput.innerHTML = '';

  },

  resetReloadUserFilesFlag() {
    window.__BRYTHON__.executionBindings.reload_user_files_flag = false;
  },

  prepare() {
    window.__BRYTHON__.executionBindings.is_running = true;
    window.__BRYTHON__.executionBindings.prev_line_info = undefined;
    window.__BRYTHON__.executionBindings.stop_execution = false;
    window._Canvas__keydown_event_handler_functions = [];
    window._Canvas__keyup_event_handler_functions = [];
    window._Canvas__click_event_handler_function = undefined;
    window._Canvas__right_click_event_handler_function = undefined;

  },

  wrapSource(sourceCode, codeAnchor, verificationCode) {
    let pos = verificationCode.indexOf(codeAnchor);
    let spaces = '';
    if (pos > 0) {
      pos--;
      while ((verificationCode[pos] === ' ') && (pos >= 0)) {
        spaces += ' ';
        pos--;
      }
    }
    if (spaces.length > 0) {
      let shiftedContent = '';
      sourceCode.split('\n').forEach((v, k) => {
        if (k > 0) {
          shiftedContent += spaces + v + '\n';
        } else {
          shiftedContent += v + '\n';
        }
      });
      sourceCode = shiftedContent;
    }
    return verificationCode.replace(codeAnchor, sourceCode);
  },

  wrapSourceCodeV1(sourceCode, verificationCode) {
    if (verificationCode.indexOf('def __input(') !== -1) {
      let search = new RegExp(/input\s*\(/, 'g');
      sourceCode = sourceCode.replace(search, '__input(');
    }
    return this.wrapSource(sourceCode, '{{{ __source_content__ }}}', verificationCode);
  },

  wrapSourceCodeV2(sourceCode, verificationCode) {
    return this.wrapSource(sourceCode, '# @USER_CODE_PLACEHOLDER@ #', verificationCode);
  },

  preVerification() {
    if (window.__letpy.verification.status === 'error') {
      return {result: false, text: window.__letpy.verification.message}
    }
  },

  postVerification() {
    if ((window.__letpy.lastError !== undefined) && (window.__letpy.lastError.msg === 'unexpected indent')) {
      return {result: false, text: 'Проверьте отступы! Возможно, где-то есть лишний. Или наоборот, отступа не хватает.'}
    }
    if (window.__letpy.lastError !== undefined) {
      return {result: false, text: 'В вашей программе есть ошибки.'}
    }
    if (window.__letpy.verification.status === 'success') {
      return {result: true, text: window.__letpy.verification.message}
    }
    return {result: false, text: 'Программа работает как-то не так'}
  },

  getVerificationUtils() {
    return {
      sourceContains: function (substr) {
        return (window.__letpy.source.toLowerCase().replace(/ /g, '').indexOf(substr) !== -1)
      },
      lastErrorClassName() {
        if (window.__letpy.lastError
            && window.__letpy.lastError.__class__
            && window.__letpy.lastError.__class__.$infos
            && window.__letpy.lastError.__class__.$infos.__name__) {
          return window.__letpy.lastError.__class__.$infos.__name__;
        }
      }
    }
  },

  verifyFile_(fileObject, lessonObject) {
    return new Promise((resolve, reject) => {
      
      if (!this.brythonModule || !this.brythonStdlibModule) {
        throw "Brython module didn't load"
      }
      try {

        let worker = this.createWorker(brythonRunnerVerificationWorkerSrc);
        const modules_list = {
          'tokenize': ['.py', tokenizeModuleSrc, []],  // Our version of tokenize module with small fixes for StringPrefix (line 105)
          'colorama': ['.py', coloramaModuleSrc, []],
          'tkinter': ['.py', tkinterSrc, [], 1],
          'tkinter.constants': ['.py', tkinterSrcConstants, []],
          'tkinter._shapes': ['.py', tkinterSrcShapes, []],
          'verification': ['.py', verificationSrc, [], 1],
          'lesson': ['.py', lessonObject.vm_, [], 1]
        }

        if (lessonObject.dependencies) {
          lessonObject.dependencies.forEach((v) => {
            modules_list[v.name] = ['.py', v.source, [], 1]
          })
        }

        worker.postMessage({
          initModules: [this.brythonModule, this.brythonStdlibModule],
          // fileId: fileObject.id,
          fileContent: fileObject.content,
          fileName: fileObject.title,
          verificationTemplate: verificationTemplateSrc,
          pythonPath: [`${process.env.VUE_APP_MAIN_HOST}/api/v2/files/${fileObject.id}/module/`],
          initScripts: [
              sleepPythonSrc,
              inputPythonSrc
          ],
          locale: process.env.VUE_APP_I18N_LOCALE,
          modules: modules_list
        });

        TkinterInit(this);
        let message = null;

        let timeout = setTimeout(()=> {
          if (this.public.isRunning) {
            worker.terminate();
            this.public.isRunning = false;
            if (message) {
              if (message.result) {
                resolve(message)
              } else {
                reject(message)
              }
            }
          }
        }, 10000)

        worker.onmessage = (event) => {
          if (event.data.type === 'script.started') {
            this.public.stdout = '';
            this.public.isRunning = true;
            // TODO: set is verification
          } else if (event.data.type === 'script.finished') {
            clearTimeout(timeout);
            worker.terminate();
            this.public.isRunning = false;

            if (message) {
              if (message.result) {
                resolve(message)
              } else {
                reject(message)
              }
            }
          } else if (event.data.type === 'stdin.read') {
            
          } else if (event.data.type === 'tkinter') {
            TkinterHandle(event.data);
          } else if (event.data.type === 'verification') {
            message = null;
            clearTimeout(timeout);
            if (event.data.value.result) {
              resolve(event.data.value) 
            } else {
              reject(event.data.value)
            }
          } else if (event.data.type === 'verification_set_message') {
            message = event.data.value;
            if (message.result) {
              clearTimeout(timeout);
              worker.terminate();
              this.public.isRunning = false;
              resolve(message)
            }

          } else {
            console.log(event);
          }
        }
      } catch (e) {
        console.error(e);
      }

    });
  },

  verifyFile(fileObject, lessonObject) {
    if (this.public.version > 1) {
      return this.verifyFile_(fileObject, lessonObject);
    }
    
    window.__BRYTHON__.executionBindings.file_id = fileObject.id;
    window.__BRYTHON__.executionBindings.folder_id = fileObject.parent;

    let wrappedCode = fileObject.content;

    if (lessonObject.vm_ !== null) {
      wrappedCode = this.wrapSourceCodeV2(fileObject.content, lessonObject.vm_);
    } else if (lessonObject.cover !== null) {
      wrappedCode = this.wrapSourceCodeV1(fileObject.content, lessonObject.cover);
    }

    this.reset();
    this.prepare();
    window.__BRYTHON__.path = [`${process.env.VUE_APP_MAIN_HOST}/api/v2/files/${fileObject.id.toString()}/module/`];

    window.__letpy.is_running = false;
    window.__letpy.is_verification = true;
    window.__letpy_reactive.isVerification = true;
    window.__BRYTHON__.executionBindings.mode = 'normal';
    window.__letpy.source = String(fileObject.content);


    window.__BRYTHON__.stdout.write = (data) => {
      window.__letpy.stdout += data;
    }
    window.__BRYTHON__.stderr.write = (data) => {
      window.__letpy.stdout += data;
    }
    window.__letpy_exec_number += 1;

    this.exec(wrappedCode);
    let count = 1;

    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        let result = null;
        if (lessonObject.vmAsync) {
          this.exec(lessonObject.vmAsync);
        }

        if (lessonObject.vm_) {
          result = this.preVerification();
          if (!result) {
            let verificationJS = eval(lessonObject.vm)
            if ((verificationJS !== undefined) && (verificationJS !== null) &&  (verificationJS.verification !== undefined)) {
              result = verificationJS.verification(this.getVerificationUtils());
            }
          }
          if (!result) {
            result = this.postVerification();
          }
        } else {
          result = eval(lessonObject.hashproc).check();
        }
        if (result.result === true) {
          this.stopExecution();
          resolve(result);
          clearInterval(interval);
        }
        count += 1;
        if (count > lessonObject.maxCheckingRetries) {
          this.stopExecution();
          clearInterval(interval);
          reject(result);
        }
      }, 500)
    });
  },

  exec(sourceCode) {
    try {
      window.__BRYTHON__.frames_stack = [];
      let js = 'var $locals___letpy_exec$' + window.__letpy_exec_number + '={"__name__": "__main__"};\n';

      let compiled = window.__BRYTHON__.py2js(sourceCode, '__letpy_exec$' + window.__letpy_exec_number, '__letpy_exec$' + window.__letpy_exec_number);
      js = js + compiled.to_js();
      let catch_block = "__BRYTHON__.executionBindings.is_running = false;\n" +
          "if ($err.$py_error === undefined) {\n" +
          "    console.log('Javascript error', $err)\n" +
          "    $err = _b_.RuntimeError.$factory($err + '')\n" +
          "}\n" +
          "var name = $B.class_name($err);\n" +
          "if ($err.$py_error && ($err.__class__.__module__ !== 'builtins')){name=$err.__class__.__module__ + '.' + name}\n" +
          "if((name !=='KeyboardInterrupt') && (name !=='SystemExit')) {window.__letpy.lastError = {...$err};}\n" +
          "var $trace = _b_.getattr($err, 'infoWithInternal');\n" +
          "if (name==='KeyboardInterrupt') {$trace='Программа остановлена'}\n" +
          "else if (name==='SystemExit') {$trace=''}\n" +
          "else if (name == 'SyntaxError' || name == 'IndentationError') {\n" +
          "    var offset = $err.args[3]\n" +
          "    $trace += '\\n' + ' '.repeat(offset) + '^\\n' + name + ': ' + $err.args[0]\n" +
          "} else {\n" +
          "    $trace += '\\n' + name + ': ' + $err.args\n" +
          "}\n" +
          "try {\n" +
          "    _b_.getattr($B.stderr, 'write')($trace)\n" +
          "} catch (print_exc_err) {\n" +
          "    console.log($trace)\n" +
          "}\n";
      js = '(async function(){try{' + js + ';Object.keys(window.__BRYTHON__.imported).forEach(function(key, index){if (window.__BRYTHON__.imported[key].__loader__ && window.__BRYTHON__.imported[key].__loader__.$infos && window.__BRYTHON__.imported[key].__loader__.$infos.__name__ === \'ImporterPath\') {delete window.__BRYTHON__.imported[key]}});}catch($err) {Object.keys(window.__BRYTHON__.imported).forEach(function(key, index){if (window.__BRYTHON__.imported[key].__loader__ && window.__BRYTHON__.imported[key].__loader__.$infos && window.__BRYTHON__.imported[key].__loader__.$infos.__name__ === \'ImporterPath\') {delete window.__BRYTHON__.imported[key]}});;' + catch_block + '}__BRYTHON__.executionBindings.is_running = false;})();';

      window.eval(js);
    } catch ($err) {
      window.__letpy.lastError = {...$err};
      window.__BRYTHON__.executionBindings.is_running = false;
      let _b_ = window.__BRYTHON__.builtins;
      let $B = window.__BRYTHON__;

      let trace = '';
      if ($err.__class__ !== undefined) {
        let name = $B.class_name($err);
        trace = _b_.getattr($err, 'info')
        if (name == 'SyntaxError' || name == 'IndentationError') {
          var offset = $err.args[3]
          trace += '\n    ' + ' '.repeat(offset) + '^' +
              '\n' + name + ': ' + $err.args[0]
        } else {
          trace += '\n' + name
          if ($err.args[0] && $err.args[0] !== _b_.None) {
            trace += ': ' + _b_.str.$factory($err.args[0])
          }
        }
      } else {
        console.log($err)
        trace = $err + ""
      }
      try {
        $B.$getattr($B.stderr, 'write')(trace)
        try {
          $B.$getattr($B.stderr, 'flush')()
        } catch ($err) {
          console.log($err)
        }
      } catch (print_exc_err) {
        console.log(trace)
      }
      // Throw the error to stop execution
      // throw $err
    }
  },

  runFile(file, debug) {
    if (this.public.version > 1) {
      this._runFile(file);
      return;
    }

    this.reset();
    this.prepare();

    window.__BRYTHON__.executionBindings.file_id = file.id;
    window.__BRYTHON__.executionBindings.folder_id = file.parent;
    window.__letpy.source = file.content;

    if (debug) {
      window.__BRYTHON__.executionBindings.mode = 'debug';
    } else {
      window.__BRYTHON__.executionBindings.mode = 'normal';
    }
    window.__BRYTHON__.path = [`${process.env.VUE_APP_MAIN_HOST}/api/v2/files/${file.id.toString()}/module/`];

    window.__BRYTHON__.stdout.write = (data) => {
      // clear console when somebody prints "\033[H\033[J", end=""
      // https://stackoverflow.com/questions/517970/how-to-clear-the-interpreter-console

      if (data.indexOf("[H[J") !== -1) {
        window.__letpy_reactive.stdout = '';
        return;
      }

      data = data.replace(/__letpy_exec\$\d+/g, file.name)
      window.__letpy_reactive.stdout += data;
    }
    window.__BRYTHON__.stderr.write = (data) => {
      data = data.replace(/__letpy_exec\$\d+/g, file.name)
      window.__letpy_reactive.stdout += data;
    }
    window.__letpy_exec_number += 1;
    this.exec(file.content)
  },

  nextStep() {
    window.__BRYTHON__.executionBindings.wait_for_next_step = false;
  },

  getCurrentLineNumber() {
    let locals = window.__BRYTHON__.executionBindings.current_step_locals;
    if (locals.$line_info === undefined) {
      return null;
    }
    return parseInt(locals.$line_info.split(',')[0]);
  },

  getLocalsForCurrentStep() {
    let locals = window.__BRYTHON__.executionBindings.current_step_locals;
    let result = [];

    for (let key in locals) {
      if ((!key.toString().startsWith('$'))
          && (key.toString() !== '__doc__')
          && (key.toString() !== '__file__')
          && (key.toString() !== '__name__')
          && (key.toString() !== '__package__')
          && (key.toString() !== '__annotations__')
      )
        result.push({'name': key, 'value': locals[key]['value'], 'type': locals[key]['type']})
    }
    return result;
  },

  stopExecution() {
    if (this.public.isRunning) {
      this.stopWorkerExecution();
    }
    window.__BRYTHON__.executionBindings.stop_execution = true;
    if (window.__BRYTHON__.executionBindings.mode === 'debug') {
      window.__BRYTHON__.executionBindings.wait_for_next_step = false;
    }
  },

  testCode() {
    let z = 'let b="a=1";eval(b);console.log(a);'
    window.eval(z);
  },

  getErrorHint() {
    let exception = window.__letpy.lastError;
    if (exception) {
      let exceptionName = window.__BRYTHON__.class_name(exception);
      let exceptionText = window.__BRYTHON__.builtins.getattr(exception, 'infoWithInternal');
      if (exception.isHandlerError) {
          let lines = exceptionText.split('\n');
          if (lines.length === 3) {
              exceptionText = lines[0] + '\n' + lines[2];
          }
        }

        let res = exceptionText.match(new RegExp(/line \d+/, 'g'));
        if (!res) {
          return
        }
        let lineNumber = res[0].replace('line ', '');
        let context = {
          exceptionName: exceptionName,
          exceptionText: exceptionText,
          exception: exception,
          lineNumber: lineNumber
        }
        let handler = InterpreterHints[exceptionName];
        if (handler) {
          return handler(context)
        }
      return exceptionName;
    }
  }

}



